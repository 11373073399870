<template>
  <div class="page">
    <div class="content">
      <div class="left">
        <div class="video" v-if="type == 'video'">
          <video class="video" controls :src="$host + info.resource"></video>
        </div>
        <div class="pick" v-if="type == 'article'">
          <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            v-model="slide"
            img-width="784"
            img-height="480"
            controls
            label-next=""
            label-prev=""
          >
            <b-carousel-slide
              v-for="(item, index) in list"
              :key="index"
              :img-src="item"
              style="width: 784px; height: 480px"
            ></b-carousel-slide>
          </b-carousel>
          <div class="course">
            <div v-for="(item, index) in list" :key="index" @click="selectImg(index)">
              <img :src="item" style="width: auto; height: 100px" />
            </div>
          </div>
        </div>
        <div class="article-title">
          {{ info.title }}
        </div>
        <p class="sp">
          {{ info.content }}
        </p>
        <div class="end">
          <span style="margin-right: 30px"
            ><strong>发表于：</strong>{{ $timeFormat(info.createtime) }}</span
          >
          <span style="margin-right: 30px"
            ><b-icon
              :icon="info.is_like ? 'heart-fill' : 'heart'"
              style="margin-right: 5px; color: red"
              class="article-icon"
              @click="like"
            ></b-icon
            >{{ info.like_count }}</span
          >
          <span style="margin-right: 10px"
            ><b-icon
              :icon="info.is_favorite ? 'star-fill' : 'star'"
              style="margin-right: 5px; color: #fd9852"
              class="article-icon"
              @click="shoucang"
            ></b-icon
            >{{ info.favorite_count }}</span
          >
          <!-- <span style="margin-right: 30px"><b-icon icon="box-arrow-up-right" style="margin-right: 5px"></b-icon>{{info.share_count}}</span> -->
        </div>
        <div class="add">
          <div class="add-title">评论</div>
          <b-form-textarea
            class="textarea"
            v-model="text"
            placeholder="评论.."
            maxlength="120"
            rows="3"
            no-resize
          ></b-form-textarea>
          <div style="text-align: right">
            <b-button variant="danger" class="result" @click="submit(null)"
              >回复</b-button
            >
          </div>
        </div>
        <div class="comment">
          <div class="add-title">共 {{ total }} 条评论</div>

          <div class="list">
            <div v-for="(item, index) in comments" :key="index" class="chat">
              <div class="chat-avatar" style="width: 60px">
                <img class="avatar" src="../../static/doctor/avatar.png" />
              </div>
              <div style="width: 584px">
                <div class="chat-nickname">{{ item.author_name }}</div>
                <div class="chat-content">{{ item.content }}</div>
                <div class="chat-time">{{ $timeFormat(item.createtime) }}</div>
              </div>
              <div style="width: 140px; text-align: right">
                <b-icon
                  style="margin-right: 30px; color: #999"
                  icon="chat-dots"
                  @click="callback(item.id, item.author_name)"
                ></b-icon>

                <b-icon
                  style="margin-right: 7.5px; color: #fb776a"
                  :icon="item.is_comment_like == 'false' ? 'heart' : 'heart-fill'"
                ></b-icon>
                <span style="margin-right: 10px">{{ item.like_count }}</span>
              </div>
              <div class="child" v-if="item.sub_comment_list.items.length > 0">
                <div
                  v-for="(child, i) in item.sub_comment_list.items"
                  :key="i"
                  style="width: 700px; display: flex; margin: 10px 0"
                >
                  <div class="chat-avatar" style="width: 60px">
                    <img class="avatar" src="../../static/doctor/avatar.png" />
                  </div>
                  <div style="width: 520px">
                    <div class="chat-nickname">{{ child.author_name }}</div>
                    <div class="chat-content">{{ child.content }}</div>
                    <div class="chat-time">{{ $timeFormat(child.createtime) }}</div>
                  </div>
                  <div style="width: 120px">
                    <b-icon
                      style="margin-right: 30px; color: #999"
                      icon="chat-dots"
                      @click="callback(item.id, child.author_name, child.id)"
                    ></b-icon>

                    <b-icon
                      style="margin-right: 7.5px; color: #fb776a"
                      :icon="item.is_comment_like == 'false' ? 'heart' : 'heart-fill'"
                    ></b-icon>
                    <span style="margin-right: 10px">{{ child.like_count }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.id == call" class="ccomment">
                <div class="cancel" @click="cancel">关闭</div>
                <b-textarea
                  v-model="children_text"
                  :placeholder="'回复：' + item.author_name"
                  maxlength="120"
                  rows="3"
                  no-resize
                  style="width: 750px; margin: 10px 17px; height: 96px"
                >
                </b-textarea>
                <div style="text-align: right; width: 770px">
                  <b-button variant="danger" class="result" @click="submit(item.id)"
                    >回复</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-pagination v-model="page" :total-rows="count" per-page="5"></b-pagination>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="article-card">
          <div class="article-card-title">文章作者</div>
          <div class="article-card-info">
            <div style="width: 80px; text-align: center">
              <img
                :src="$host + userInfo.avatar"
                style="width: 60px; height: 60px; border-radius: 50%"
              />
            </div>
            <div style="width: 120px">
              <div class="article-card-nickname">{{ userInfo.nickname }}</div>
              <div class="article-card-desc">
                <span>女</span>
                <span style="margin-left: 20px">{{ userInfo.age }}岁</span>
              </div>
            </div>
          </div>
          <div class="selects">
            <div class="options">
              <img class="img" src="../../static/community/community_ico_notes@2x.png" />
              她的笔记( {{ userInfo.science_count }} )
            </div>
            <div class="options">
              <img class="img" src="../../static/community/community_ico_praise@2x.png" />
              她的获赞( {{ userInfo.attention_count }} )
            </div>
            <div class="options">
              <img class="img" src="../../static/community/community_ico_fan@2x.png" />
              她的粉丝( {{ userInfo.fans_count }} )
            </div>
          </div>
          <div class="btm">
            <div class="gzbtn" @click="guanzhu">{{userInfo.is_friend?'已关注':'关注'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      type: "article",
      list: [],
      text: "",
      id: "",
      comments: [],
      page: 1,
      total: 0,
      call: "",
      children_text: "",
      callname: "",
      child: "",
      slide: 0,
      sliding: null,
      userInfo: {},
      count: 0,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getInfo();
    this.getComment();
  },
  watch: {
    page: function () {
      this.getComment();
    },
  },
  methods: {
    getInfo() {
      this.$http
        .detail({
          id: this.$route.query.id,
        })
        .then((res) => {
          let { data, code, msg } = res;
          if (code == 1) {
            if (data.resource.indexOf(".mp4") <= -1) {
              this.type = "article";
              let list = data.resource.split(",");
              //   console.log(this.list)
              let arr = [];
              list.forEach((item) => {
                arr.push(this.$host + item);
              });
              this.list = arr;
            } else {
              this.type = "video";
            }
            this.info = data;
            this.getWriter(data.user_id);
            console.log(this.type);
            //   console.log(data)
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    submit(id) {
      let data = { content_id: this.id, content: this.text };
      if (id) {
        data.for_comment_id = id;
        data.content = this.children_text;
      }
      if (this.child != "") {
        data.for_comment_id = this.child;
      }
      if (data.content == "") return this.$store.commit("showToast", "请输入评论内容");
      this.$http.addcomment(data).then((res) => {
        let { code, msg } = res;
        if (code == 1) {
          this.$store.commit("showToast", "评论成功");
          this.text = "";
          this.children_text = "";
          setTimeout(() => {
            this.getComment();
          }, 1000);
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getWriter(id) {
      this.$http
        .otherinfo({
          id: id,
        })
        .then((res) => {
          let { data, code } = res;
          if (code == 1) {
            this.userInfo = data;
          } else {
            this.$store.commit("showToast", "获取作者信息失败~");
          }
        });
      // this.$axios({
      //   url: '/proxy/api/userapp.other/info',
      //   params: {
      //     id: id,
      //   },
      //   method: 'get'
      // }).then(res=> {
      //   console.log(res)
      // })
    },
    getComment(id = null) {
      let data = {
        limit: 5,
        page: this.page,
        content_id: this.id,
        start_id: id,
        is_sub: id ? "is_sub" : "",
      };
      this.$http.comment(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.comments = data.items;
          this.total = data.comment_total;
          this.count = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    callback(id = null, name = "", child = null) {
      this.call = id;
      this.callname = name;
      if (child) {
        this.child = child;
      } else {
        this.child = "";
      }
    },
    cancel() {
      this.call = "";
      this.callname = "";
      this.child = "";
    },
    like() {
      this.$http
        .like({
          id: this.id,
        })
        .then((res) => {
          let { code, msg } = res;
          if (code == 1) {
            if (this.info.is_like) {
              this.$store.commit("showToast", "取消点赞成功");
            } else {
              this.$store.commit("showToast", "点赞成功");
            }
            this.getInfo();
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    shoucang() {
      this.$http.setfavorite({ id: this.id }).then((res) => {
        let { code, msg } = res;
        if (code == 1) {
          if (this.info.is_favorite) {
            this.$store.commit("showToast", "取消收藏成功");
          } else {
            this.$store.commit("showToast", "收藏成功");
          }
          this.getInfo();
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    selectImg(index) {
      console.log(index);
      this.slide = index;
    },
    guanzhu() {
      if(this.userInfo.is_friend) {
        return;
      }
      this.$http.setattention({
        id: this.info.user_id,
        ident: this.info.user_ident
      }).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", res.msg);
          setTimeout(() => {
            // this.getData();
            this.getWriter(this.info.user_id)
          }, 2000);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./article.scss";
.course {
  margin: 20px 0;
  width: 784px;
  display: flex;
  justify-content: space-around;
  height: 120px;
  padding: 10px 0;
  background: #fcfcfc;
}
</style>
